import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import BusinessForm from '@/components/Businesses/BusinessForm';

const CreateBusinessModal = () => {

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button className="w-full py-2 rounded-md text-lg mt-5">
                  Create a new business
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]" onInteractOutside={(e) => e.preventDefault()}>
                <DialogHeader>
                    <DialogTitle className="text-xl">Create a new business</DialogTitle>
                    <DialogDescription>
                        Fill in your business details to create a new business and get started.
                    </DialogDescription>
                </DialogHeader>
               <BusinessForm />
            </DialogContent>
        </Dialog>
    );
};

export default CreateBusinessModal;
