import * as c from '@/redux/constants/businesses';

export const getBusiness = ({ businessId }) => ({
    type: c.GET_BUSINESS,
    businessId
});
export const getBusinessSuccess = ({ payload }) => ({
    type: c.GET_BUSINESS_SUCCESS,
    payload,
});
export const getBusinessFailure = () => ({
    type: c.GET_BUSINESS_FAILURE
});

export const editBusiness = ({ businessId, formData}) => ({
    type: c.EDIT_BUSINESS,
    businessId,
    formData
});
export const editBusinessSuccess = ({ payload }) => ({
    type: c.EDIT_BUSINESS_SUCCESS,
    payload,
});
export const editBusinessFailure = () => ({
    type: c.EDIT_BUSINESS_FAILURE
});

export const createBusiness = ({ formData }) => ({
    type: c.CREATE_BUSINESS,
    formData
});
export const createBusinessSuccess = ({ payload }) => ({
    type: c.CREATE_BUSINESS_SUCCESS,
    payload,
});
export const createBusinessFailure = () => ({
    type: c.CREATE_BUSINESS_FAILURE
});