import { put, call } from 'redux-saga/effects';
import * as a from '@/redux/actions/businesses';
import { toast } from 'react-toastify';
import { formDataRequest } from '@/utils/formDataRequest';
import { createErrorMessage } from '@/utils/tools';

export function* editBusinessSaga ({ businessId, formData }) {
    try {
        const response = yield call(
            formDataRequest,
            `/businesses/${businessId}/edit/`,
            'PATCH',
            formData
        );

        const payload = response?.data?.data;
        yield put(a.editBusinessSuccess(({ payload: payload })));
        toast.success('Your business details have been updated.');
    } catch (error) {
        console.log('error', error);
        let errorMessage = createErrorMessage('There was an error editing your business details.', error?.response?.data)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.editBusinessFailure());
    }
}