import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/events';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

export function* createAndDownloadZipSaga({ zipJson }) {
    try {
        const zip = new JSZip();
        const mediaBaseUrl = zipJson.media_base_url;
        const data = zipJson.data;

        for (const item of data) {
            const folder = zip.folder(item.name);
            for (const file of item.files) {
                try {
                    const fileUrl = file.startsWith('/') ? `${mediaBaseUrl}${file}` : `${mediaBaseUrl}${file}`;
                    const response = yield call(fetch, fileUrl);
                    const blob = yield call([response, 'blob']);
                    folder.file(file.split('/').pop(), blob);
                } catch (error) {
                    console.log("Failed to zip image.", error);
                }
            }
        }

        const zipBlob = yield zip.generateAsync({ type: 'blob' });
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        yield call(saveAs, zipBlob, `grouped_photos_${timestamp}.zip`);
        yield put(a.createDownloadableZipSuccess());
    } catch (error) {
        console.log('error', error);
        toast.error("There was an error creating your ZIP file.");
        yield put(a.createDownloadableZipFailure());
    }
}
