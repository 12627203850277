import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/user';
import { request } from '@/utils/request';

export function *loginUserSaga ({ username, password }) {
    try {
        const response = yield call(
            request,
            '/accounts/login/',
            'POST',
            { body: JSON.stringify({ username, password }) },
            {},
            false
        );

        localStorage.setItem('token', response.token);
        yield put(a.loginUserSuccess(({ 
            payload:{
                ...response.user,
                token: response.token,
            }
        })));
    } catch (error) {
        console.log('error:', error);
        let errorMessage = 'Login failed:';

        // Check if error is a Response object and can be parsed as JSON
        let errorData = null;
        if (error instanceof Response) {
            try {
                errorData = yield error.json();
            } catch (jsonError) {
                console.error('Failed to parse error response as JSON:', jsonError);
            }
        }

        // If errorData is still null, it means we couldn't parse the error
        if (!errorData) {
            errorMessage += ' Unexpected error occurred.';
        } else {
            if (errorData.error) {
                errorMessage += ` ${errorData.error}`;
            } else {
                const errors = errorData.errors;
                if (errors && typeof errors === 'object') {
                    const firstKey = Object.keys(errors)[0];
                    if (firstKey && errors[firstKey] && Array.isArray(errors[firstKey])) {
                        errorMessage = errors[firstKey][0];
                    }
                }
            }
        }

        yield put(a.loginUserFailure({ error: errorMessage }));
    }
}
