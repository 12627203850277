export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const EDIT_EVENT = 'EDIT_EVENT';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const START_POLLING_EVENT = 'START_POLLING_EVENT';
export const STOP_POLLING_EVENT = 'STOP_POLLING_EVENT';

export const GET_GROUPED_PHOTOS_ZIP = 'GET_GROUPED_PHOTOS_ZIP';
export const GET_GROUPED_PHOTOS_ZIP_SUCCESS = 'GET_GROUPED_PHOTOS_ZIP_SUCCESS';
export const GET_GROUPED_PHOTOS_ZIP_FAILURE = 'GET_GROUPED_PHOTOS_ZIP_FAILURE';

export const CREATE_DOWNLOADABLE_ZIP = 'CREATE_DOWNLOADABLE_ZIP';
export const CREATE_DOWNLOADABLE_ZIP_SUCCESS = 'CREATE_DOWNLOADABLE_ZIP_SUCCESS';
export const CREATE_DOWNLOADABLE_ZIP_FAILURE = 'CREATE_DOWNLOADABLE_ZIP_FAILURE';
