import React from 'react';
import { useSelector } from 'react-redux';
import { CloudDownload, Loader2 } from "lucide-react"
import { CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';


const DownloadGroupsStep = ({ photoGroupsZipDownloadUrl, photosCount, portraitsCount, currentStep, status }) => {
    const email = useSelector(state => state.user?.email);
    const { isGroupedPhotosZipLoading, isZipping } = useSelector(state => state.events.state);
    const { isUploading } = useSelector(state => state.photos.state);

    const isProcessing = status.toLowerCase() === 'processing';
    const isDownloadable = status.toLowerCase() === 'completed';

    const handleDownloadGroupsClick = () => {
        window.open(photoGroupsZipDownloadUrl, '_blank');
    }

    return (
        <>
            <CardHeader className="text-left">
                <h4 className="font-[sans-serif] text-md font-bold text-gray-500/90">{currentStep.label}</h4>
                <CardTitle className="text-2xl font-weight-400 text-neutral-800">{currentStep.title}</CardTitle>
                <CardDescription>{currentStep.description}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="mt-12">
                    <h2 className="text-[1.8rem] text-center font-weight-500 text-neutral-800">You are all set! 🥳</h2>
                    <p className="text-md mt-5">You’ve successfully uploaded
                        <span className="font-weight-500 text-primary">
                            &nbsp;{photosCount} photo{photosCount > 1 && 's'}
                        </span>
                        {portraitsCount > 0 &&
                            <>
                                &nbsp;and
                                <span className="font-weight-500 text-primary">
                                    &nbsp;{portraitsCount} portrait{portraitsCount > 1 && 's'}
                                </span>
                            </>
                        }
                        .&nbsp;
                        {isProcessing ?
                            'Our system is currently processing your files, and the results will be ready for download soon.'
                            :
                            'Your files have been processed, and the results are ready for download.'
                        }
                    </p>
                    <p className="mt-4">
                        {isProcessing ?
                            <>
                                We’ll also notify you via email at
                                <span className="font-weight-500 text-primary">&nbsp;{email}.</span>
                            </>
                            :
                            'Feel free to upload more files, and our system will automatically process them.'
                        }
                    </p>
                </div>
                <div className="mt-12 mb-14 flex justify-center items-center">
                    {(isGroupedPhotosZipLoading || isZipping || isUploading) ?
                        <Button variant="disabled" size={"lg"} className="text-lg cursor-default">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                            <span>{isUploading ? 'Uploading' : 'Preparing'} files</span>
                        </Button>
                        : isProcessing ?
                            <Button variant="disabled" size={"lg"} className="text-lg cursor-default">
                                <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                Grouping photos
                            </Button>

                            : isDownloadable ?
                                <Button size={"lg"} className="text-lg" onClick={handleDownloadGroupsClick}>
                                    <CloudDownload className="mr-3"/>
                                    Download groups
                                </Button>
                                :
                                <></>
                    }
                </div>
            </CardContent>
        </>

    );
};

export default DownloadGroupsStep;