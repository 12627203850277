import { takeLatest } from 'redux-saga/effects';

import * as c from '@/redux/constants/events';
import { UPLOAD_PHOTOS_COMPLETED } from '@/redux/constants/photos'

import { getEventsSaga } from './getEvents';
import { createEventSaga } from './createEvent';
import { deleteEventSaga } from './deleteEvent';
import { editEventSaga } from './editEvent';
import { getEventSaga, watchPollEventSaga } from './getEvent';
import { getGroupedPhotosZipSaga } from './getGroupedPhotosZip';
import { createAndDownloadZipSaga } from './createAndDownloadZip';
import { pingUploadCompletedSaga } from './pingUploadCompleted';


export default function *eventsSaga() {
    yield takeLatest(c.GET_EVENTS, getEventsSaga);
    yield takeLatest(c.CREATE_EVENT, createEventSaga);
    yield takeLatest(c.DELETE_EVENT, deleteEventSaga);
    yield takeLatest(c.EDIT_EVENT, editEventSaga);
    yield takeLatest(c.GET_EVENT, getEventSaga);
    yield takeLatest(c.GET_GROUPED_PHOTOS_ZIP, getGroupedPhotosZipSaga);
    yield takeLatest(c.CREATE_DOWNLOADABLE_ZIP, createAndDownloadZipSaga);
    yield takeLatest(UPLOAD_PHOTOS_COMPLETED, pingUploadCompletedSaga);
    yield watchPollEventSaga();
}
