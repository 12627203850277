import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import 'styles/global.css'

import Landing from '@/containers/Landing';
import Login from '@/containers/Login';
import Signup from '@/containers/Signup';
import CreateBusiness from '@/containers/CreateBusiness';
import ResetPassword from '@/containers/ResetPassword';
import SetNewPassword from '@/containers/SetNewPassword';
import Events from '@/containers/Events';
import ManageEvent from '@/containers/ManageEvent';
import PrivateRoute from "@/components/routes/PrivateRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
        <BrowserRouter>
            <ToastContainer position="top-center" style={{ width: "450px" }} />
            <Routes>
                <Route path="/" element={<Landing />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/signup" element={<Signup />}/>
                <Route path="/create-business" element={<PrivateRoute><CreateBusiness /></PrivateRoute>}/>
                <Route path="/reset-password" element={<ResetPassword />}/>
                <Route path="/reset-password/:uid/:token" element={<SetNewPassword />}/>
                <Route path="/events"  element={ <PrivateRoute><Events/></PrivateRoute> } />
                <Route path="/events/:eventId"  element={ <PrivateRoute><ManageEvent/></PrivateRoute> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
