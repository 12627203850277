import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/user';
import { request } from '@/utils/request';

export function *signupUserSaga ({ firstName, lastName, username, password, recaptchaToken }) {
    try {
        const response = yield call(
            request,
            '/accounts/',
            'POST',
            {
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    email: username,
                    username,
                    password,
                    recaptcha_token: recaptchaToken
                })
            },
            {},
            false
        );

        localStorage.setItem('token', response.token);
        yield put(a.signupUserSuccess(({
            payload:{
                ...response.user,
                token: response.token,
            }
        })));
    } catch (error) {
        console.log('error:', error);
        let errorMessage = 'Signup failed:';

        // Check if error is a Response object and can be parsed as JSON
        let errorData = null;
        if (error instanceof Response) {
            try {
                errorData = yield error.json();
            } catch (jsonError) {
                console.error('Failed to parse error response as JSON:', jsonError);
            }
        }

        // If errorData is still null, it means we couldn't parse the error
        if (!errorData) {
            errorMessage += ' Unexpected error occurred.';
        } else {
            if (errorData.error) {
                errorMessage += ` ${errorData.error}`;
            } else {
                const errors = errorData.errors;
                if (errors && typeof errors === 'object') {
                    const firstKey = Object.keys(errors)[0];
                    if (firstKey && errors[firstKey] && Array.isArray(errors[firstKey])) {
                        errorMessage = errors[firstKey][0];
                    }
                }
            }
        }

        yield put(a.signupUserFailure({ error: errorMessage }));
    }
}
