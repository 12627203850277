import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBusiness, editBusiness } from '@/redux/actions';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import CountryDropdownSelector from '@/components/ui/CountryDropdownSelector';
import { isValidUrl, isValidPhone } from '@/utils/tools';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


const BusinessForm = ({ business }) => {
    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const { isEditBusinessLoading } = useSelector(state => state.businesses.state);
    const countries = useSelector(state => state.lookups.countries);

    const [newLogo, setNewLogo] = useState(null);
    const [name, setName] = useState(business?.name || '');
    const [addressLine, setAddressLine] = useState(business?.address_line || '');
    const [city, setCity] = useState(business?.city || '');
    const [postcode, setPostcode] = useState(business?.postcode || '');
    const [country, setCountry] = useState(business?.country || '');
    const [vatNo, setVatNo] = useState(business?.vat_no || '');
    const [phone, setPhone] = useState(business?.phone || '');
    const [website, setWebsite] = useState(business?.website || '');
    const [businessErrors, setBusinessErrors] = useState({});

    const detailsChanged = name !== business?.name || addressLine !== business?.address_line || city !== business?.city || postcode !== business?.postcode || country !== business?.country || vatNo !== business?.vat_no || phone !== business?.phone || website !== business?.website || newLogo;

    const handleFileSelect = () => {
        if (isEditBusinessLoading) return;

        fileInputRef.current.click();
    };

    const handleBusinessUpdate = () => {
        const newErrors = {};
        if (!name || name?.length < 2) newErrors.name = 'Please fill in your business name';
        if (!addressLine || addressLine?.length < 4) newErrors.addressLine = 'Please fill in your business address line';
        if (!city || city?.length < 2) newErrors.city = 'Please fill a city';
        if (!postcode || postcode?.length < 4) newErrors.postcode = 'Please fill in a postcode';
        if (!country) newErrors.country = 'Please select a country';
        if (vatNo && vatNo?.length < 5) newErrors.vatNo = 'Please fill in a valid VAT number';
        if (!phone || !isValidPhone(phone)) newErrors.phone = 'Please provide a valid phone number';
        if (website && !isValidUrl(website)) newErrors.website = 'Please fill in a valid website';

        setBusinessErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        if (name && name !== business?.name) {
            formData.append('name', name);
        }
        if (addressLine && addressLine !== business?.address_line) {
            formData.append('address_line', addressLine);
        }
        if (city && city !== business?.city) {
            formData.append('city', city);
        }
        if (postcode && postcode !== business?.postcode) {
            formData.append('postcode', postcode);
        }
        if (country && country !== business?.country) {
            formData.append('country', country);
        }
        if (vatNo && vatNo !== business?.vat_no) {
            formData.append('vat_no', vatNo);
        }
        if (phone && phone !== business?.phone) {
            formData.append('phone', phone);
        }

        let formattedWebsite = website;
        if (!formattedWebsite.startsWith('https://') && !formattedWebsite.startsWith('http://')) {
            formattedWebsite = `https://${formattedWebsite}`;
        }

        if (website && website !== business?.website) {
            formData.append('website', formattedWebsite);
        }
        if (newLogo) {
            formData.append('logo', newLogo);
        }

        if (business) {
            Array.from(formData.keys()).length > 0 && dispatch(editBusiness({ businessId: business.id, formData }));
        } else {
            dispatch(createBusiness({ formData }));
        }
        setNewLogo(null);
    };

    return (
        <div className="grid gap-1 py-1">
            {business && <h3 className="text-lg font-weight-400 col-span-12">Business details</h3>}
            <p className="col-span-12 text-muted-foreground font-weight-400 mt-1">Business logo</p>
            <div className="flex items-center col-span-12">
                <Avatar className="w-14 h-14">
                    {business?.logo || newLogo ?
                        <AvatarImage src={newLogo ? URL.createObjectURL(newLogo) : business?.logo}/>
                        :
                        <AvatarFallback>{name ? name[0] : '📸'}</AvatarFallback>
                    }
                </Avatar>
                <p
                    className="ml-4 text-sm text-primary font-weight-500 text-right cursor-pointer hover:underline"
                    onClick={handleFileSelect}
                >
                    Upload a new business logo
                </p>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => setNewLogo(e.target.files[0])}
                    className="hidden"
                    accept="image/*"
                />
            </div>

            <div className="col-span-12 grid gap-1 py-1 mt-4">
                <Label htmlFor="name" className="text-muted-foreground font-weight-400 col-span-12 mt-2">
                    Business name<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
                <Input id="name" className="col-span-12" value={name} onChange={(e) => setName(e.target.value)}/>
                {businessErrors.name && <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.name}</p>}

                <div className="grid grid-cols-12 gap-5 col-span-12 mt-4">
                    <Label htmlFor="addressLine" className="text-muted-foreground font-weight-400 col-span-8">
                        Business address line<span className="text-red-800 text-sm ml-0.5">*</span>
                    </Label>
                    <Label htmlFor="postcode" className="text-muted-foreground font-weight-400 col-span-4">
                        Business postcode<span className="text-red-800 text-sm ml-0.5">*</span>
                    </Label>
                </div>
                <div className="grid grid-cols-12 gap-5 col-span-12">
                    <div className="col-span-8">
                        <Input id="addressLine" className="col-span-12" value={addressLine}
                               onChange={(e) => setAddressLine(e.target.value)}/>
                        {businessErrors.addressLine &&
                            <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.addressLine}</p>}
                    </div>
                    <div className="col-span-4">
                        <Input id="postcode" className="col-span-12" value={postcode}
                               onChange={(e) => setPostcode(e.target.value)}/>
                        {businessErrors.postcode &&
                            <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.postcode}</p>}
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-5 col-span-12 mt-4">
                    <Label htmlFor="city" className="text-muted-foreground font-weight-400 col-span-6">
                        Business city<span className="text-red-800 text-sm ml-0.5">*</span>
                    </Label>
                    <Label htmlFor="country" className="text-muted-foreground font-weight-400 col-span-6">
                        Business country<span className="text-red-800 text-sm ml-0.5">*</span>
                    </Label>
                </div>
                <div className="grid grid-cols-12 gap-5 col-span-12">
                    <div className="col-span-6">
                        <Input id="city" className="col-span-12" value={city} onChange={(e) => setCity(e.target.value)}/>
                        {businessErrors.city &&
                            <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.city}</p>}
                    </div>
                    <div className="col-span-6">
                        <CountryDropdownSelector
                            countries={countries}
                            selectedCountry={country}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                        {businessErrors.country &&
                            <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.country}</p>}
                    </div>
                </div>

                 <div className="grid grid-cols-2 gap-5 col-span-12 mt-4">
                     <Label htmlFor="vatNo" className="text-muted-foreground font-weight-400">
                         Business VAT number
                     </Label>
                     <Label htmlFor="phone" className="text-muted-foreground font-weight-400">
                         Business contact number<span className="text-red-800 text-sm ml-0.5">*</span>
                     </Label>
                </div>
                <div className="grid grid-cols-2 gap-5 col-span-12">
                    <div>
                        <Input id="vatNo" value={vatNo} onChange={(e) => setVatNo(e.target.value)}/>
                        {businessErrors.vatNo && <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.vatNo}</p>}
                    </div>
                    <div>
                        <PhoneInput
                            defaultCountry="gr"
                            preferredCountries={["gr", "cy"]}
                            inputClassName="w-full"
                            inputStyle={{ height: "40px", borderRadius: "0 6px 6px 0" }}
                            countrySelectorStyleProps={{ buttonStyle: { height: "40px", padding: "0 10px", borderRadius: "6px 0 0 6px" } }}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                        />
                        {businessErrors.phone && <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.phone}</p>}
                    </div>
                </div>

                <Label htmlFor="website" className="text-muted-foreground font-weight-400 col-span-12 mt-4">Business website</Label>
                <Input id="website" className="col-span-12" value={website} onChange={(e) => setWebsite(e.target.value)}/>
                {businessErrors.website && <p className="text-red-500 text-sm mt-1 w-max">{businessErrors.website}</p>}
            </div>

            <div className="flex justify-end col-span-12 mt-4">
                {isEditBusinessLoading && <Spinner className="mr-3" size="sm"/>}
                <Button
                    variant={isEditBusinessLoading || !detailsChanged ? 'disabled' : ''}
                    onClick={!isEditBusinessLoading ? handleBusinessUpdate : null}
                    className="col-span-2"
                >
                    {business ? 'Update' : 'Create'} business
                </Button>
            </div>
        </div>
    );
};

export default BusinessForm;
