import * as c from '@/redux/constants/photos';

export const uploadPhoto = ({ file, eventId, isPortrait }) => ({
    type: c.UPLOAD_PHOTO,
    file,
    eventId,
    isPortrait
});

export const uploadPhotos = ({ files, eventId, isPortraits, eventTitle }) => ({
    type: c.UPLOAD_PHOTOS,
    files,
    eventId,
    isPortraits,
    eventTitle
});

export const uploadPhotosCompleted = ({ businessId, eventId, objectType }) => ({
    type: c.UPLOAD_PHOTOS_COMPLETED,
    businessId,
    eventId,
    objectType
});

export const uploadPhotoSuccess = ({ file }) => ({
    type: c.UPLOAD_PHOTO_SUCCESS,
    file
});
export const uploadPhotoFailure = ({ file }) => ({
    type: c.UPLOAD_PHOTO_FAILURE,
    file
});

export const toggleUploadDownloadProgress = () => ({
    type: c.TOGGLE_UPLOAD_DOWNLOAD_PROGRESS
});

