import * as c from '@/redux/constants/lookups';

export const getCountries = () => ({
    type: c.GET_COUNTRIES
});
export const getCountriesSuccess = ({ payload }) => ({
    type: c.GET_COUNTRIES_SUCCESS,
    payload,
});
export const getCountriesFailure = () => ({
    type: c.GET_COUNTRIES_FAILURE
});

export const getEventTypes = () => ({
    type: c.GET_EVENT_TYPES
});
export const getEventTypesSuccess = ({ payload }) => ({
    type: c.GET_EVENT_TYPES_SUCCESS,
    payload,
});
export const getEventTypesFailure = () => ({
    type: c.GET_EVENT_TYPES_FAILURE
});