import { useEffect } from 'react';


export const useBeforeUnload = (isInProgress, message) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isInProgress) {
        event.preventDefault();
        event.returnValue = '';
        return message;
      }
    };

    if (isInProgress) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (isInProgress) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [isInProgress, message]);
};


