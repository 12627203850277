import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/businesses';

const initialState = {
    business: {
        id: 0,
        name: '',
        slug: '',
        address_line: '',
        city: '',
        postcode: '',
        country: '',
        vat_no: '',
        phone: '',
        website: '',
        logo: '',
        manager: null,
        verified: false,
        monthly_photo_quota: 0,
        cost_per_photo: 0,
        current_month_photos_count: 0
    },
    errors: {},
    state: {
        isBusinessLoading: true,
        isEditBusinessLoading: false,
        isCreateBusinessLoading: false,
    },

};

const businessesData = handleActions(
    new Map([
        [
            c.GET_BUSINESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isBusinessLoading: true
                }
            })
        ],
         [
            c.GET_BUSINESS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                business: payload,
                state: {
                    ...state.state,
                    isBusinessLoading: false
                },
            })
        ],
        [
            c.GET_BUSINESS_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isBusinessLoading: false
                }
            })
        ],
        [
            c.EDIT_BUSINESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditBusinessLoading: true
                }
            })
        ],
        [
            c.EDIT_BUSINESS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                business: payload,
                state: {
                    ...state.state,
                    isEditBusinessLoading: false
                },
            })
        ],
        [
            c.EDIT_BUSINESS_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditBusinessLoading: false
                }
            })
        ],
        [
            c.CREATE_BUSINESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCreateBusinessLoading: true
                }
            })
        ],
        [
            c.CREATE_BUSINESS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                business: payload,
                state: {
                    ...state.state,
                    isCreateBusinessLoading: false
                },
            })
        ],
        [
            c.CREATE_BUSINESS_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCreateBusinessLoading: false
                }
            })
        ],
    ]),
    initialState
);

export default businessesData;