import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/photos';
import { CREATE_DOWNLOADABLE_ZIP } from '@/redux/constants/events';

const initialState = {
    errors: {},
    state: {
        isUploading: false,
        isUploadDownloadProgressMinimized: false,
    },
    uploads: {
        eventTitle: '',
        selectedFiles: [],
        totalFiles: 0,
        pendingFiles: 0,
        successful: [],
        failed: []
    }
};

const photosData = handleActions(
    new Map([
        [
            c.UPLOAD_PHOTOS,
            (state, { files, eventTitle }) => ({
                ...state,
                uploads: {
                    ...state.uploads,
                    eventTitle: eventTitle,
                    selectedFiles: files,
                    totalFiles: files.length,
                    pendingFiles: files.length,
                    successful: [],
                    failed: []
                },
                state: {
                    ...state.state,
                    isUploading: true,
                    isUploadDownloadProgressMinimized: false
                }
            })
        ],
        [
            c.UPLOAD_PHOTOS_COMPLETED,
            (state) => ({
                ...state,
                uploads: {
                    ...state.uploads,
                    selectedFiles: [],
                    totalFiles: 0,
                    pendingFiles: 0
                },
                state: {
                    ...state.state,
                    isUploading: false
                }
            })
        ],
        [
            c.UPLOAD_PHOTO,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                }
            })
        ],
         [
            c.UPLOAD_PHOTO_SUCCESS,
            (state, { file }) => ({
                ...state,
                state: {
                    ...state.state,
                },
                uploads: {
                    ...state.uploads,
                    pendingFiles: --state.uploads.pendingFiles,
                    successful: [
                        ...state.uploads.successful,
                        file
                    ]

                }
            })
        ],
        [
            c.UPLOAD_PHOTO_FAILURE,
            (state, { file }) => ({
                ...state,
                state: {
                    ...state.state,
                },
                uploads: {
                    ...state.uploads,
                    pendingFiles: --state.uploads.pendingFiles,
                    failed: [
                        ...state.uploads.failed,
                        file
                    ]
                }
            })
        ],
        [
            c.TOGGLE_UPLOAD_DOWNLOAD_PROGRESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isUploadDownloadProgressMinimized: !state.state.isUploadDownloadProgressMinimized
                }
            })
        ],
        [
            CREATE_DOWNLOADABLE_ZIP,
            (state, {eventTitle}) => ({
                 ...state,
                uploads: {
                    ...state.uploads,
                    eventTitle: eventTitle
                }
            })
        ],
    ]),
    initialState
);

export default photosData;