import React from 'react';
import Header from '@/containers/Header';
import Footer from '@/containers/Footer';


export const withHeaderAndFooter = (WrappedComponent) =>
    function (props) {
        return (
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow">
                    <WrappedComponent {...props} />
                </main>
                <Footer />
            </div>
        );
    };

export default withHeaderAndFooter;