import React from 'react';
import moment from 'moment';
import { Button } from '@/components/ui/button';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';


const DateRangePicker = ({ date, handleDateChange }) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="dates"
                    variant={"outline"}
                    className={`w-full justify-start text-left font-normal ${
                        !date && 'text-muted-foreground'
                    }`}
                >
                    <CalendarIcon className="mr-2 h-4 w-4"/>
                    {date?.from ?
                        (
                            date?.to && !moment(date.from).isSame(date.to) ?
                                <>
                                    {moment(date?.from).format("DD MMMM YYYY")}
                                    &nbsp;&minus;&nbsp;
                                    {moment(date?.to).format("DD MMMM YYYY")}
                                </>
                                :
                                moment(date?.from).format("DD MMMM YYYY")
                        ) :
                        <span>Pick your event date(s)</span>
                    }
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={handleDateChange}
                    numberOfMonths={1}
                />
            </PopoverContent>
        </Popover>
    )
};

export default DateRangePicker;