import { call, put, delay, race, take, select } from 'redux-saga/effects';
import * as a from '@/redux/actions/events';
import { request } from '@/utils/request';
import { START_POLLING_EVENT, STOP_POLLING_EVENT } from '@/redux/constants/events';

export function* getEventSaga ({ businessId, eventId }) {
    try {
        const response = yield call(
            request,
            `/businesses/${businessId}/events/${eventId}/`,
            'GET'
        );

        yield put(a.getEventSuccess(({
            payload: response
        })));

    } catch (error) {
        console.log('error', error);
        if (error instanceof Response) {
            const errorData = yield error.json().catch(jsonError => console.error('Failed to parse error response as JSON:', jsonError) || null);
            errorData && console.log(errorData);
        }
        yield put(a.getEventFailure());
    }
}

export function* pollEventSaga ({ businessId, eventId }) {
    try {
        while (true) {
            yield call(getEventSaga, { businessId, eventId });
            const eventStatus = yield select((state) => state.events.event.status);

            if (eventStatus.toLowerCase() !== 'processing') {
                yield put(a.stopPollingEvent());
                break;
            }

            yield delay(60000);
        }
    } catch (error) {
        console.log('error', error);
        yield put(a.stopPollingEvent());
    }
}

export function* watchPollEventSaga() {
    while (true) {
        const { businessId, eventId } = yield take(START_POLLING_EVENT);
        yield race([
            call(pollEventSaga, { businessId, eventId }),
            take(STOP_POLLING_EVENT)
        ]);
    }
}