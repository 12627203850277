import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/user';

const initialState = {
    token: '',
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    business: {
        id: 0,
        name: '',
        logo: '',
        verified: false,
    },
    profile: {
        id: 0,
        name: '',
        avatar: '',
        business_account: false
    },
    errorMessage: '',
    errors: {},
    state: {
        isEditUserLoading: false,
    }
};

const userData = handleActions(
    new Map([
        [
            c.LOGIN_USER,
            (state) => ({   
                ...state,
                errorMessage: ''
            })  
        ],
        [
            c.LOGIN_USER_SUCCESS,
            (state, { payload }) => ({
                ...state,
                ...payload,
                errorMessage: ''
            })
        ],
        [
            c.LOGIN_USER_FAILURE,
            (state, { error }) => ({
                ...state,
                errorMessage: error
            })
        ],
        [
            c.SIGNUP_USER,
            (state) => ({
                ...state,
                errorMessage: ''
            })
        ],
        [
            c.SIGNUP_USER_SUCCESS,
            (state, { payload }) => ({
                ...state,
                ...payload,
                errorMessage: ''
            })
        ],
        [
            c.SIGNUP_USER_FAILURE,
            (state, { error }) => ({
                ...state,
                errorMessage: error
            })
        ],
        [
            c.RESET_ERROR,
            (state) => ({
                ...state,
                isError: false,
                errorMessage: '',
                errors: [],
            })
        ],
        [
            c.LOGOUT_USER,
            () => ({
                ...initialState,
            })
        ],
        [
            c.GET_USER_DATA_SUCCESS,
            (state, { payload }) => ({
                ...state,
                ...payload
            })
        ],
        [
            c.EDIT_USER,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditUserLoading: true
                }
            })
        ],
        [
            c.EDIT_USER_SUCCESS,
            (state, { payload }) => ({
                ...state,
                ...payload,
                state: {
                    ...state.state,
                    isEditUserLoading: false
                }
            })
        ],
        [
            c.EDIT_USER_FAILURE,
            (state ) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditUserLoading: false
                }
            })
        ],
    ]),
    initialState
);

export default userData;
