import React from 'react';
import { ReactComponent as StepCompletedIcon } from '@/assets/icons/step_completed.svg';
import { getStepIcon, enabledStep } from '@/utils/tools';

const Stepper = ({ steps, currentStep, setCurrentStep, classification, photosCount, portraitsCount }) => {
    return (
        <div className="flex sm:pl-12 lg:pl-30 xl:pl-36 ml-1 items-center h-full">
            <div className="flex items-center flex-col justify-center font-[sans-serif] w-max">
                {
                    steps.map(step => {
                            const isCompleted = currentStep.index > step.index;
                            const isNext = currentStep.index < step.index;
                            const isEnabledStep = enabledStep(step, classification, photosCount, portraitsCount);
                            return <div key={step.index} className="flex items-center flex-col relative">
                                <div className={`absolute top-3 md:top-0 left-full ml-4 w-max`}>
                                    <p className={`text-md font-bold ${isNext ? 'text-gray-400/90' : 'text-gray-500/90'}`}>
                                        {step.label}
                                    </p>
                                    <h6 className={`hidden md:block text-xl font-bold ${isNext ? 'text-gray-400/90' : 'text-neutral-800'}`}>
                                        {step.title}
                                    </h6>
                                </div>
                                <div className={`w-12 h-12 shrink-0 mx-[-1px] border-2 ${isEnabledStep ? 'cursor-pointer' : ''}
                                                ${isNext ? 'border-gray-400 text-gray-400' : isCompleted ? 'border-green-500 text-primary' : 'border-primary text-primary'} 
                                                p-2 flex items-center justify-center rounded-full`}
                                    onClick={() => isEnabledStep && setCurrentStep(step)}
                                >
                                    {isCompleted ?
                                        <StepCompletedIcon/>
                                        :
                                        getStepIcon(step, steps.length)
                                    }
                                </div>
                                {step.index !== steps.length - 1 &&
                                    <div className={`w-1 h-32 ${isCompleted ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                                }
                            </div>
                        }
                    )
                }
            </div>
        </div>
    );
};

export default Stepper;
