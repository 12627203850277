import { takeLatest } from 'redux-saga/effects';

import * as c from '@/redux/constants/businesses';

import { getBusinessSaga } from './getBusiness';
import { editBusinessSaga } from './editBusiness';
import { createBusinessSaga } from './createBusiness';

export default function *businessesSagas() {
    yield takeLatest(c.GET_BUSINESS, getBusinessSaga);
    yield takeLatest(c.EDIT_BUSINESS, editBusinessSaga);
    yield takeLatest(c.CREATE_BUSINESS, createBusinessSaga);
}
