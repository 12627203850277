import React from 'react';
import Select from 'react-select';

const EventTypeDropdownSelector = ({ eventTypes, selectedEventType, onChange }) => {
    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'hsl(0, 0%, 70%)' : 'hsl(0, 0%, 90%)',
            '&:hover': {
                borderColor: 'hsl(0, 0%, 70%)',
            },
            boxShadow: state.isFocused ? '0 0 0 1px hsl(0, 0%, 70%)' : 'none',
        }),
    };

    const options = eventTypes.map((eventType) => ({
        value: eventType.id,
        label: eventType.name,
    }));

    // const formatOptionLabel = ({ label, imgSrc }) => (
    //     <div className="flex items-center">
    //         <img
    //             src={imgSrc}
    //             alt={label}
    //             className="w-4 h-4 mr-2"
    //         />
    //         {label}
    //     </div>
    // );

    return (
        <Select
            styles={customStyles}
            value={options.find(option => option.value === selectedEventType)}
            onChange={(selectedOption) => onChange({ target: { value: selectedOption.value } })}
            options={options}
            placeholder="Select an event type"
            // formatOptionLabel={formatOptionLabel}
        />
    );
};

export default EventTypeDropdownSelector;
