import { put, call } from 'redux-saga/effects';
import * as a from '@/redux/actions/events';
import { toast } from 'react-toastify';
import { formDataRequest } from '@/utils/formDataRequest';
import { createErrorMessage } from '@/utils/tools';

export function* editEventSaga ({ businessId, eventId, formData }) {
    try {
        const response = yield call(
            formDataRequest,
            `/businesses/${businessId}/events/${eventId}/edit/`,
            'PATCH',
            formData
        );

        const payload = response?.data?.data;
        yield put(a.editEventSuccess(({ payload: payload })));
    } catch (error) {
        console.log('error', error);
        let errorMessage = createErrorMessage('There was an error editing your event.', error?.response?.data)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.editEventFailure());
    }
}