import React from 'react';
import PropTypes from 'prop-types';

const ErrorFallback = ({ resetErrorBoundary, error }) => {
    console.error(error);
    return (
        <div role="alert" className="error-screen">
            <p>Your session has expired. Please log in again</p>
            <button onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('persist:root');
                window.location.href = '/login';
                resetErrorBoundary();

            }}
            >Go to login
            </button>
        </div>
    );
};

ErrorFallback.propTypes = {
    resetErrorBoundary: PropTypes.func.isRequired,
    error: PropTypes.object
};

export default ErrorFallback;