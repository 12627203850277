const apiURL = process.env.REACT_APP_API_DOMAIN;
const apiProtocol = process.env.REACT_APP_PROTOCOL;

export const request = (url, method = 'GET', params, headers, isAuthorizationRequired = true) => {
    const fullUrl = `${apiProtocol}${apiURL}/react${url}`;
    const token = localStorage.getItem('token');

    return fetch(fullUrl, {
        method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: isAuthorizationRequired && token ? `Token ${token}` : '',
            ...headers
        },
        ...params
    })
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .then(response => {
            if (response.detail) {
                return response.detail;
            }
            if (response.success) {
                return response.data;
            }
            throw response.error;
        })
        .catch(err => {
            if (err?.message &&
                (err?.message.includes('Failed to fetch') || err?.message.includes('Could not connect to the server'))
            ) {
                throw new Error('Network error: Failed to fetch or connect to the server');
            }
            throw err;
        });
};

export default request;
