import { call, put } from 'redux-saga/effects';
import { request } from '@/utils/request';
import * as a from '@/redux/actions/events';
import { toast } from 'react-toastify';
import { createErrorMessage } from '@/utils/tools';

export function *deleteEventSaga ({ businessId, eventId, navigate }) {
    try {
        yield call(
            request,
            `/businesses/${businessId}/events/${eventId}`,
            'DELETE'
        );

        yield put(a.deleteEventSuccess());
        navigate('/events');

    } catch (error) {
        console.log('error', error);
        const errorData = error instanceof Response ? yield error.json() : null;
        let errorMessage = createErrorMessage('There was an error deleting your event.', errorData)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.deleteEventFailure());
    }
}
