import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/lookups';

const initialState = {
    countries: [],
    eventTypes: [],
    errors: {},
    state: {
        isCountriesLoading: false,
        isEventTypesLoading: false,
    },

};

const lookupsData = handleActions(
    new Map([
        [
            c.GET_COUNTRIES,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCountriesLoading: true
                }
            })
        ],
         [
            c.GET_COUNTRIES_SUCCESS,
            (state, { payload }) => ({
                ...state,
                countries: payload,
                state: {
                    ...state.state,
                    isCountriesLoading: false
                },
            })
        ],
        [
            c.GET_COUNTRIES_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCountriesLoading: false
                }
            })
        ],
        [
            c.GET_EVENT_TYPES,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventTypesLoading: true
                }
            })
        ],
         [
            c.GET_EVENT_TYPES_SUCCESS,
            (state, { payload }) => ({
                ...state,
                eventTypes: payload,
                state: {
                    ...state.state,
                    isEventTypesLoading: false
                },
            })
        ],
        [
            c.GET_EVENT_TYPES_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventTypesLoading: false
                }
            })
        ]
    ]),
    initialState
);

export default lookupsData;