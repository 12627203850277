import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/user';
import { request } from '@/utils/request';
import { toast } from 'react-toastify';
import { createErrorMessage } from '@/utils/tools';

export function *resetPasswordSaga ({ email }) {
    try {
        const response = yield call(
            request,
            '/accounts/password_reset/',
            'POST',
            { body: JSON.stringify({ email }) },
            {},
            false
        );
        toast.success(`A reset password link was sent to ${email}`);
        yield put(a.resetPasswordSuccess({ payload: response }));
    } catch (error) {
        console.log('error', error);
        const errorData = error instanceof Response ? yield error.json() : null;
        let errorMessage = createErrorMessage('There was an error sending your reset link.', errorData)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.resetPasswordFailure(error));
    }
}


export function *setNewPasswordSaga ({ password, confirmPassword, uid, resetToken, navigate }) {
    try {
        const response = yield call(
            request,
            `/accounts/password_reset/confirm/${uid}/${resetToken}/`,
            'POST',
            { body: JSON.stringify({ password, confirm_password: confirmPassword }) },
            {},
            false
        );

        toast.success('Your password was successfully changed');
        yield put(a.setNewPasswordSuccess({payload: response}));
        navigate(`/login`);
    } catch (error) {
        console.log('error', error);
        const errorData = error instanceof Response ? yield error.json() : null;
        let errorMessage = createErrorMessage('There was an error resetting your password.', errorData)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.setNewPasswordFailure(error));
    }
}