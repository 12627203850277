import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { getCountries } from '@/redux/actions';
import { Settings } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import AccountSettings from '@/components/Settings/AccountSettings';
import BusinessForm from '@/components/Businesses/BusinessForm';

const SettingsModal = ({ user, business }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('Account');

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    return (
        <Dialog>
            <DialogTrigger asChild>
                <div className="flex align-middle items-center justify-start w-full hover:bg-gray-100">
                    <Settings className="ml-3 h-[20px] w-[20px]" />
                    <button className="block px-3 py-2 text-gray-800 hover:bg-gray-100">Settings</button>
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]" onInteractOutside={(e) => e.preventDefault()}>
                <DialogHeader>
                    <DialogTitle className="text-xl">Settings</DialogTitle>
                </DialogHeader>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        <button
                            className={`
                                ${activeTab === 'Account' ? 'border-primary text-primary' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} 
                                whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm`
                            }
                            onClick={() => setActiveTab('Account')}
                        >
                            Account
                        </button>
                        <button
                            className={`
                                ${activeTab === 'Business' ? 'border-primary text-primary' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                                whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm`
                            }
                            onClick={() => setActiveTab('Business')}
                        >
                            Business
                        </button>
                    </nav>
                </div>
                {activeTab === 'Account' && <AccountSettings user={user} />}
                {activeTab === 'Business' && <BusinessForm business={business} />}
            </DialogContent>
        </Dialog>
    );
};

export default SettingsModal;
