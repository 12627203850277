import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Separator } from "@/components/ui/separator";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from '@/redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '@/utils/tools';
import logo from '@/assets/icons/logo-vertical.png';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const token = useSelector(state => state.user?.token);

  useEffect(() => {
    token && navigate('/events');
  }, [token, navigate]);

  const handleResetPassword = () => {
    if (!email || !isValidEmail(email)) {
      setError('Please provide a valid email address')
      return
    }

    dispatch(resetPassword({ email: email.trim() }));
  };

  return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center mt-8 w-[400px]">
          <Link to="/">
            <img src={logo} alt="Logo" width="350" />
          </Link>
          <Card className="w-full max-w-md px-7 py-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-weight-500 text-center mb-4">Reset password</h2>
            <p className="text-center mb-8">Enter your email, and if an account is found, we'll send you a unique link to reset your password.</p>
            <div className="mb-6">
              <label htmlFor="email" className="block font-medium text-gray-700">
                Email address
              </label>
              <Input
                  id="email"
                  type="email"
                  required
                  className="mt-1 block w-full"
                  onChange={(e) => setEmail(e.target.value)}
              />
              {error && <p className="text-red-500 text-sm mt-1 w-max">{error}</p>}
            </div>
            <Button onClick={handleResetPassword} className="w-full py-2 rounded-md text-lg">
              Reset password
            </Button>
            <Link to="/login">
              <p className="text-muted-foreground text-sm cursor:pointer hover:underline cursor-pointer mt-4 text-center">
                Not meant to be here? Please <b>click here</b>.
              </p>
            </Link>
            <Separator className="my-4 bg-black bg-opacity-20"/>
            <p className="text-md text-center">
              Need help accessing your account?&nbsp;<br/>
              Get in touch via&nbsp;
              <a href="mailto:support@bestshot.ai" className="text-primary">
                support@bestshot.ai
              </a>
            </p>
          </Card>
        </div>
      </div>
  );
};

export default ResetPassword;
