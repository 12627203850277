import * as c from '@/redux/constants/events';

export const getEvents = ({ businessId }) => ({
    type: c.GET_EVENTS,
    businessId
});
export const getEventsSuccess = ({ payload }) => ({
    type: c.GET_EVENTS_SUCCESS,
    payload
});
export const getEventsFailure = () => ({
    type: c.GET_EVENTS_FAILURE
});

export const createEvent = ({ businessId, formData, navigate }) => ({
    type: c.CREATE_EVENT,
    businessId,
    formData,
    navigate
});
export const createEventSuccess = ({ payload }) => ({
    type: c.CREATE_EVENT_SUCCESS,
    payload
});
export const createEventFailure = () => ({
    type: c.CREATE_EVENT_FAILURE
});

export const getEvent = ({ businessId, eventId }) => ({
    type: c.GET_EVENT,
    businessId,
    eventId
});
export const getEventSuccess = ({ payload }) => ({
    type: c.GET_EVENT_SUCCESS,
    payload
});
export const getEventFailure = () => ({
    type: c.GET_EVENT_FAILURE
});

export const deleteEvent = ({ businessId, eventId, navigate }) => ({
    type: c.DELETE_EVENT,
    businessId,
    eventId,
    navigate
});
export const deleteEventSuccess = () => ({
    type: c.DELETE_EVENT_SUCCESS,
});
export const deleteEventFailure = () => ({
    type: c.DELETE_EVENT_FAILURE
});

export const editEvent = ({ businessId, eventId, formData }) => ({
    type: c.EDIT_EVENT,
    businessId,
    eventId,
    formData
});
export const editEventSuccess = ({ payload }) => ({
    type: c.EDIT_EVENT_SUCCESS,
    payload
});
export const editEventFailure = () => ({
    type: c.EDIT_EVENT_FAILURE
});

export const startPollingEvent = ({ businessId, eventId }) => ({
    type: c.START_POLLING_EVENT,
    businessId,
    eventId
});

export const stopPollingEvent = () => ({
    type: c.STOP_POLLING_EVENT
});


export const getGroupedPhotosZip = ({ businessId, eventId, eventTitle }) => ({
    type: c.GET_GROUPED_PHOTOS_ZIP,
    businessId,
    eventId,
    eventTitle
});
export const getGroupedPhotosZipSuccess = ()  => ({
    type: c.GET_GROUPED_PHOTOS_ZIP_SUCCESS,
});
export const getGroupedPhotosZipFailure = () => ({
    type: c.GET_GROUPED_PHOTOS_ZIP_FAILURE
});

export const createDownloadableZip = ({ zipJson, eventTitle }) => ({
    type: c.CREATE_DOWNLOADABLE_ZIP,
    zipJson,
    eventTitle
});

export const createDownloadableZipSuccess = () => ({
    type: c.CREATE_DOWNLOADABLE_ZIP_SUCCESS
});

export const createDownloadableZipFailure = () => ({
    type: c.CREATE_DOWNLOADABLE_ZIP_FAILURE
});