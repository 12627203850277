import { call } from 'redux-saga/effects';
import { request } from '@/utils/request';

export function* pingUploadCompletedSaga ({ businessId, eventId, objectType }) {
    try {
        yield call(
            request,
            `/businesses/${businessId}/events/${eventId}/upload_completed?type=${objectType}`,
            'GET'
        );
    } catch (error) {
        console.log('error', error);
        if (error instanceof Response) {
            const errorData = yield error.json().catch(jsonError => console.error('Failed to parse error response as JSON:', jsonError) || null);
            errorData && console.log(errorData);
        }
    }
}