import * as c from '@/redux/constants/user';

export const loginUser = ({ username, password }) => ({
    type: c.LOGIN_USER,
    username,
    password,
});
export const loginUserSuccess = ({ payload }) => ({
    type: c.LOGIN_USER_SUCCESS,
    payload,
});
export const loginUserFailure = ({ error }) => ({
    type: c.LOGIN_USER_FAILURE,
    error,
});

export const signupUser = ({ firstName, lastName, username, password, recaptchaToken }) => ({
    type: c.SIGNUP_USER,
    firstName,
    lastName,
    username,
    password,
    recaptchaToken
});
export const signupUserSuccess = ({ payload }) => ({
    type: c.SIGNUP_USER_SUCCESS,
    payload,
});
export const signupUserFailure = ({ error }) => ({
    type: c.SIGNUP_USER_FAILURE,
    error,
});

export const resetPassword = ({ email }) => ({
    type: c.RESET_PASSWORD,
    email,
});
export const resetPasswordSuccess = ({ payload }) => ({
    type: c.RESET_PASSWORD_SUCCESS,
    payload,
});
export const resetPasswordFailure = ({ error }) => ({
    type: c.RESET_PASSWORD_FAILURE,
    error,
});

export const setNewPassword = ({ password, confirmPassword, uid, resetToken, navigate }) => ({
    type: c.SET_NEW_PASSWORD,
    password,
    confirmPassword,
    uid,
    resetToken,
    navigate
});
export const setNewPasswordSuccess = ({ payload }) => ({
    type: c.SET_NEW_PASSWORD_SUCCESS,
    payload,
});
export const setNewPasswordFailure = ({ error }) => ({
    type: c.SET_NEW_PASSWORD_FAILURE,
    error,
});

export const resetError = () => ({
    type: c.RESET_ERROR,
});

export const logoutUser = () => ({
    type: c.LOGOUT_USER
});

export const getUserData = () => ({
    type: c.GET_USER_DATA
});
export const getUserDataSuccess = ({ payload }) => ({
    type: c.GET_USER_DATA_SUCCESS,
    payload,
});
export const getUserDataFailure = () => ({
    type: c.GET_USER_DATA_FAILURE
});

export const editUser = ({ userId, formData}) => ({
    type: c.EDIT_USER,
    userId,
    formData
});
export const editUserSuccess = ({ payload }) => ({
    type: c.EDIT_USER_SUCCESS,
    payload,
});
export const editUserFailure = () => ({
    type: c.EDIT_USER_FAILURE
});
