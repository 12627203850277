import { put, call } from 'redux-saga/effects';
import * as a from '@/redux/actions/businesses';
import { getUserData } from '@/redux/actions/user';
import { toast } from 'react-toastify';
import { formDataRequest } from '@/utils/formDataRequest';
import { createErrorMessage } from '@/utils/tools';

export function* createBusinessSaga ({ formData }) {
    try {
        const response = yield call(
            formDataRequest,
            `/businesses/`,
            'POST',
            formData
        );

        const payload = response?.data?.data;
        yield put(a.createBusinessSuccess(({ payload: payload })));
        yield put(getUserData());
    } catch (error) {
        console.log('error', error);
        let errorMessage = createErrorMessage('There was an error creating your business.', error?.response?.data)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.createBusinessFailure());
    }
}