import { takeLatest } from 'redux-saga/effects';

import * as c from '@/redux/constants/user';

import { loginUserSaga } from './login';
import { signupUserSaga } from './signup';
import { resetPasswordSaga, setNewPasswordSaga } from './resetPassword';
import { getUserSaga } from './getUserData';
import { editUserSaga } from './editUser';

export default function *userSagas() {
    yield takeLatest(c.LOGIN_USER, loginUserSaga);
    yield takeLatest(c.SIGNUP_USER, signupUserSaga);
    yield takeLatest(c.RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(c.SET_NEW_PASSWORD, setNewPasswordSaga);
    yield takeLatest(c.GET_USER_DATA, getUserSaga);
    yield takeLatest(c.EDIT_USER, editUserSaga);
}
