import React, { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { logoutUser, getUserData } from '@/redux/actions';
import { Card } from '@/components/ui/card';
import { Separator } from "@/components/ui/separator";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from '@/redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import CreateBusinessModal from '@/components/Businesses/CreateBusinessModal';
import logo from '@/assets/icons/logo-vertical.png';

const CreateBusiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.user);
  const { business }  = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
        user?.token && dispatch(getUserData());
    }, [dispatch, user?.token]);

  useEffect(() => {
    business && business?.id && navigate('/events');
  }, [navigate, business]);

  const handleLogOut = () => {
        dispatch(logoutUser());
        localStorage.removeItem('token');
        localStorage.removeItem('persist:root');
        navigate('/login');
    };

  return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center mt-8 w-[500px]">
          <Link to="/">
            <img src={logo} alt="Logo" width="350" />
          </Link>
          <Card className="w-full max-w-lg px-7 py-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-weight-500 text-center mb-8">Create a business</h2>
            <h4 className="text-lg font-weight-400">Is your business already on bestShot.ai?</h4>
            <p className="mt-2">If your business is already on bestShot.ai, please contact us at&nbsp;
              <a href="mailto:support@bestshot.ai" className="text-primary">
                support@bestshot.ai
              </a>
              &nbsp;to gain access to your business account. We'll help you get started quickly.</p>
            <Button
                onClick={() => window.location.href = 'mailto:support@bestshot.ai?subject=Request%20to%20join%20Business'}
                variant="outline"
                className="w-full py-2 rounded-md text-lg mt-5">
              Request to join business
            </Button>

            <div className="relative flex items-center my-10">
              <Separator className="flex-grow bg-black bg-opacity-20"/>
              <span className="absolute px-4 text-black bg-white -translate-x-1/2 left-1/2">OR</span>
            </div>

            <h4 className="text-lg font-weight-400">New to bestShot.ai?</h4>
            <p className="mt-2">If you’re new here, setting up your business takes less than a minute. Get started below and let AI organize your photos effortlessly!</p>
            <CreateBusinessModal />
            <button onClick={handleLogOut} className="mt-8 w-full">
              <p className="text-muted-foreground text-sm cursor:pointer hover:underline cursor-pointer mt-4 text-center">
                Not meant to be here? Please <b>click here</b>.
              </p>
            </button>
          </Card>
        </div>
      </div>
  );
};

export default CreateBusiness;
