import React, { useRef, useEffect } from 'react';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Separator } from "@/components/ui/separator";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, resetError } from '@/redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@/assets/icons/logo-vertical.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailRef = useRef('');
  const passwordRef = useRef('');

  const errorMessage = useSelector(state => state.user?.errorMessage);
  const { token, business }  = useSelector(state => state.user);

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    if (token && business && business?.id) {
      navigate('/events');
    } else if (token) {
      navigate('/create-business');
    }
  }, [token, navigate, business]);

  const handleLogin = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email && password) {
      dispatch(loginUser({
        username: email.trim(),
        password: password.trim(),
      }));
    }
  };

  return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center mt-8 w-[500px]">
          <Link to="/">
            <img src={logo} alt="Logo" width="350" />
          </Link>
          <Card className="w-full max-w-lg px-7 py-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-weight-500 text-center mb-6">Login</h2>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <Label htmlFor="email" className="font-weight-400">Email</Label>
                <Input
                    id="email"
                    type="email"
                    ref={emailRef}
                    required
                    className="mt-1 block w-full"
                />
              </div>
              <div className="mb-6">
                <Label htmlFor="password" className="font-weight-400">Password
                </Label>
                <Input
                    id="password"
                    type="password"
                    ref={passwordRef}
                    required
                    className="mt-1 block w-full"
                />
                <Link to="/reset-password">
                  <p className="text-muted-foreground text-sm mt-1 cursor:pointer hover:underline cursor-pointer">
                    Forgot your password?
                  </p>
                </Link>
              </div>
              <Button type="submit" className="w-full py-2 rounded-md text-lg">
                Login
              </Button>
              {errorMessage && <p className="mb-4 mt-2 text-center text-red-500">{errorMessage}</p>}
              <p className="text-center mt-4">
                Don't have an account?&nbsp;
                <Link to="/signup" className="font-weight-400 text-primary cursor-pointer">
                  Sign up
                </Link>
              </p>

            </form>
            <Separator className="my-3 bg-black bg-opacity-20"/>
            <p className="text-md text-center">
              Need help accessing your account?&nbsp;<br/>
              Get in touch via&nbsp;
              <a href="mailto:support@bestshot.ai" className="text-primary">
                support@bestshot.ai
              </a>
            </p>
          </Card>
        </div>
      </div>
  );
};

export default Login;
