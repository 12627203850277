import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadPhotos } from '@/redux/actions';
import { CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CloudUpload } from "lucide-react";


const FileUploader = ({ event, remainingPhotoQuota, currentStep }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const {
        id: eventId,
        title: eventTitle,
        photos_count: photosCount,
        portraits_count: portraitsCount,
        max_event_photos: maxEventPhotos,
        max_event_portraits: maxEventPortraits
    } = event;
    const { isUploading } = useSelector((state) => state.photos.state);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('')

    useEffect(() => {
        fileInputRef.current.value = null;
        setSelectedFiles([]);
        setError('');
    }, [currentStep]);

    const handleFileSelect = () => {
        if (isUploading) return;

        fileInputRef.current.click();
    };

    const handleResetSelectedFiles = () => {
        if (isUploading) return;

        fileInputRef.current.value = null;
        setSelectedFiles([]);
        setError('')
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        const currentlySelectedFiles = Array.prototype.slice.call(files);
        setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...currentlySelectedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (isUploading) return;

        const files = event.dataTransfer.files;
        const currentlySelectedFiles = Array.prototype.slice.call(files);
        setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...currentlySelectedFiles]);
    };

    const handleUploadSelectedPhotos = () => {
        const isPortraits = currentStep.value === 'portraits';
        const totalSelectedFiles = selectedFiles.length;

        if (isPortraits) {
            if (portraitsCount + totalSelectedFiles > maxEventPortraits) {
                setError('You’ve reached the maximum number of portraits allowed for this event.');
                return;
            }
        } else {
            if (photosCount + totalSelectedFiles > maxEventPhotos) {
                setError('You’ve reached the maximum number of photos allowed for this event.');
                return;
            }

            if (totalSelectedFiles > remainingPhotoQuota) {
                setError('You’ve reached your monthly photo quota limit. Please contact out team to request an increase in your quota.');
                return;
            }
        }
        dispatch(uploadPhotos({ files: selectedFiles, eventId, isPortraits, eventTitle }));
        setSelectedFiles([]);
    }

    return (
        <>
            <CardHeader className="text-left">
                <h4 className="font-[sans-serif] text-md font-bold text-gray-500/90">{currentStep.label}</h4>
                <CardTitle className="text-2xl font-weight-400 text-neutral-800">{currentStep.title}</CardTitle>
                <CardDescription>{currentStep.description}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex justify-between">
                    <div className="mt-5 flex space-x-4">
                        {selectedFiles.length > 0 ?
                            <Button size={"lg"} className="text-lg"
                                    onClick={handleUploadSelectedPhotos}>
                                <CloudUpload className="mr-3"/>
                                Upload {selectedFiles.length > 0 && `${selectedFiles.length} `} file{selectedFiles.length !== 1 && `s`}
                            </Button>
                            :
                            <Button size={"lg"} className="text-lg"
                                    onClick={handleFileSelect}
                                    variant={isUploading ? 'disabled' : ''}>
                                <CloudUpload className="mr-3"/>
                                {isUploading ? 'Uploading...' : `Select ${currentStep.value}`}
                            </Button>
                        }
                        {selectedFiles.length > 0 &&
                            <Button
                                variant="outline"
                                size="lg"
                                className="text-primary font-weight-500 text-lg"
                                onClick={handleResetSelectedFiles}
                            >
                                Reset
                            </Button>
                        }
                    </div>
                </div>
                {error && <p className="text-red-500 mt-2">{error}</p>}
            </CardContent>
            <CardContent
                className="m-6 mx-4 flex flex-col items-center justify-center border-2 border-dashed border-zinc-400 dark:border-zinc-800 rounded-lg p-10 space-y-4"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <CloudUpload className="w-16 h-16 text-zinc-500 dark:text-zinc-600"/>
                <p className="text-gray-500 dark:text-gray-300">Drag and drop your photos or click
                    the button below to select {selectedFiles.length > 0 && `more `} files.
                </p>
                <Button variant={isUploading ? 'disabled' : 'outline'} onClick={handleFileSelect}>
                    {isUploading ? 'Uploading...' : `Select ${selectedFiles.length > 0 ? 'more ' : ''}${currentStep.value}`}

                </Button>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                    multiple
                    accept="image/*"
                />
                <div
                    className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 xl-grid-cols-8 gap-1">
                    {selectedFiles.slice(0, 14).map((file, index) => (
                        <div key={index}
                             className="sm:w-1/8 md:w-1/16 h-16 border border-gray-300 rounded-md overflow-hidden">
                            <img src={URL.createObjectURL(file)}
                                 className="w-full h-full object-cover"
                                 title={file.name} alt={file.name}
                            />
                        </div>
                    ))}
                    {selectedFiles.length > 14 && (
                        <p className="col-span-2 flex align-center items-center text-center ml-2 text-lg text-gray-600">...and {selectedFiles.length - 14} more.</p>
                    )}
                </div>
            </CardContent>
        </>

    );
};

export default FileUploader;