import React from 'react';
import { Button } from "@/components/ui/button";
import { Link } from 'react-router-dom';
import { Card } from "@/components/ui/card";
import logo from '@/assets/icons/logo-vertical.png';

const Landing = () => {

    return (
        <div className="flex items-start justify-center min-h-screen bg-gray-100">
            <Card className="flex flex-col items-center mt-32 w-full max-w-xl p-8 bg-white shadow-md rounded-lg">
                <img src={logo} alt="Logo" width="1800" height="900" />
                
                <div className="mt-4 mb-4 justify-center items-center">
                    <p className="mt-2 text-lg">
                        <strong>bestShot.ai</strong> is a state-of-the-art photo management platform that aims to transform the way events capture and share memories.
                    </p>
                    <p className="mt-2 text-lg">
                        With bestShot.ai, event organizers can easily upload, manage and distribute a huge collection of photos to their customers, ensuring a hassle-free and organized experience.
                    </p>
                </div>
                
                <Button className="mt-4 mb-4 w-56 h-10 text-lg" asChild>
                    <Link to="/login">Get started</Link>
                </Button>
                
            </Card>
        </div>
    );
};

export default Landing;