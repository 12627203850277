import { put, call } from 'redux-saga/effects';
import * as a from '@/redux/actions/events';
import { toast } from 'react-toastify';
import { formDataRequest } from '@/utils/formDataRequest';
import { createErrorMessage } from '@/utils/tools';

export function* createEventSaga ({ businessId, formData, navigate }) {
    try {
        const response = yield call(
            formDataRequest,
            `/businesses/${businessId}/events/`,
            'POST',
            formData
        );

        const payload = response?.data?.data;
        yield put(a.createEventSuccess(({
            payload: payload
        })));

        navigate(`/events/${payload.id}`);
    } catch (error) {
        console.log('error', error);
        let errorMessage = createErrorMessage('There was an error creating your event.', error?.response?.data)
        toast.error(errorMessage);
        console.log(errorMessage);
        yield put(a.createEventFailure());
    }
}