// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-6 md:px-8 md:py-0 border-b border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 md:h-16 md:flex-row text-sm text-center">
          <div className="flex items-center justify-between gap-6">
              <Link to="/" className="hover:underline">
                  Terms & Conditions
              </Link>
              <Link to="/" className="hover:underline">
                    Privacy Policy
              </Link>
          </div>
          <div className="flex items-center justify-between gap-6">
                  <a href="mailto:support@bestshot.ai" className="hover:underline">
                      Contact us
                  </a>
              <p>
                  {'Copyright © 2024 bestShot.ai'}
              </p>
          </div>
      </div>
    </footer>
  );
};

export default Footer;
