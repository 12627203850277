import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/user';
import { request } from '@/utils/request';

export function *getUserSaga () {
    try {
        const response = yield call(
            request,
            '/accounts/user/',
            'GET'
        );

        yield put(a.getUserDataSuccess(({ 
            payload: response
        })));

    } catch (error) {
        yield put(a.getUserDataFailure());
        yield put(a.logoutUser());
        localStorage.removeItem('token');
        localStorage.removeItem('persist:root');
        sessionStorage.removeItem('invitationCode');
        sessionStorage.removeItem('connect-provider');
    }
}
