import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Separator } from "@/components/ui/separator";
import { Label } from '@/components/ui/label';
import { useDispatch, useSelector } from "react-redux";
import { setNewPassword, resetError } from '@/redux/actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isValidPassword } from '@/utils/tools';
import logo from '@/assets/icons/logo-vertical.png';

const SetNewPassword = () => {
  const { uid, token: resetToken } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(state => state.user?.token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState({});

  useEffect(() => {
    token && navigate('/events');
  }, [token, navigate]);

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const handleResetPassword = () => {
    const newErrors = {};
    if (!password) newErrors.password = 'Please fill in a new password';
    if (!confirmPassword) newErrors.confirmPassword = 'Please fill in your new password again';
    if (password && !isValidPassword(password)) {
      newErrors.password = 'Password must be at least 8 characters long and contain a letter and a number.'
    }
    if (password && confirmPassword && password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setPasswordErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    dispatch(setNewPassword({ password, confirmPassword, uid, resetToken, navigate }));
    setPassword('');
    setConfirmPassword('');
  };

  return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center mt-8 w-[400px]">
          <Link to="/">
            <img src={logo} alt="Logo" width="350" />
          </Link>
          <Card className="w-full max-w-md px-7 py-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-weight-500 text-center mb-4">Set a new password</h2>
            <p className="text-center mb-8">Enter a new password to update your account.</p>
            <div className="mb-6">
              <div className="mt-4">
                <Label htmlFor="password" className="text-muted-foreground font-weight-400 col-span-12 mt-2">
                  New Password<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
                <Input id="password" type="password" className="col-span-12" value={password} onChange={(e) => setPassword(e.target.value)}/>
                {passwordErrors.password &&
                    <p className="text-red-500 text-sm mt-1">{passwordErrors.password}</p>}
              </div>
              <div className="mt-4">
                <Label htmlFor="confirmPassword" className="text-muted-foreground font-weight-400 col-span-12 mt-4">
                  Confirm New Password<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
                <Input id="confirmPassword" type="password" className="col-span-12" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                {passwordErrors.confirmPassword &&
                    <p className="text-red-500 text-sm mt-1">{passwordErrors.confirmPassword}</p>}
              </div>
            </div>
            <Button onClick={handleResetPassword} className="w-full py-2 rounded-md text-lg">
              Set new password
            </Button>
            <Link to="/login">
              <p className="text-muted-foreground text-sm cursor:pointer hover:underline cursor-pointer mt-4 text-center">
                Not meant to be here? Please <b>click here</b>.
              </p>
            </Link>
            <Separator className="my-4 bg-black bg-opacity-20"/>
            <p className="text-md text-center">
              Need help accessing your account?&nbsp;<br/>
              Get in touch via&nbsp;
              <a href="mailto:support@bestshot.ai" className="text-primary">
                support@bestshot.ai
              </a>
            </p>
          </Card>
        </div>
      </div>
  );
};

export default SetNewPassword;
