const apiURL = process.env.REACT_APP_API_DOMAIN;
const apiProtocol = process.env.REACT_APP_PROTOCOL;

export const getSignedUrlAndUploadFile = (file, eventId, isPortrait, uploadMethod = 'PUT', isAuthorizationRequired = true) => {
    const filePath = (isPortrait ? 'portraits/' : 'photos/') + file.name;
    const fullUrl = `${apiProtocol}${apiURL}/react/photos/signed_url/?file_path=${filePath}&content_type=${file.type}&event_id=${eventId}&is_portrait=${isPortrait}`;
    const token = localStorage.getItem('token');
    return fetch(fullUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': isAuthorizationRequired && token ? `Token ${token}` : '',
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw response;
            }

            return response.json()
        })
        .then((responseData) => {
            const signedUrl = responseData.data;
            return fetch(signedUrl, {
                method: uploadMethod,
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            })
        })
        .then((response) => {
            if (!response.ok) {
                console.error('Failed to upload file:', response.statusText);
                throw response;
            }
            return file
        })
        .then((file) => {
            const createPhotoUrl = `${apiProtocol}${apiURL}/react/photos/`;
            return fetch(createPhotoUrl, {
                method: 'POST',
                body: JSON.stringify({
                    'file_path': filePath,
                    'event_id': eventId,
                    'is_portrait': isPortrait
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': isAuthorizationRequired && token ? `Token ${token}` : '',
                }
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return file
        })
        .catch(err => {
            console.error('Error uploading file:', err);
            if (err?.message &&
                (err?.message.includes('Failed to fetch') || err?.message.includes('Could not connect to the server'))
            ) {
                throw new Error('Network error: Failed to fetch or connect to the server');
            }
            throw err;
        });
}