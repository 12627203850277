import axios from "axios";

const apiURL = process.env.REACT_APP_API_DOMAIN;
const apiProtocol = process.env.REACT_APP_PROTOCOL;

export const formDataRequest = (url, method = 'POST', formData) => {
    const fullUrl = `${apiProtocol}${apiURL}/react${url}`;
    const token = localStorage.getItem('token');

    return axios({
        url: fullUrl, 
        method: method.toLowerCase(),
        data: formData, 
        headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'multipart/form-data',
            }
    });
};

export default formDataRequest;
