import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import rootSaga from './redux/sagas/rootSaga';
import rootReducer from './redux/reducers/rootReducer';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { request } from './utils/request';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'company']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);
sagaMiddleware.run(rootSaga);

const reportError = (error) => {
    console.log('error',error);
    if (process.env.NODE_ENV === 'development') return;
    request(
        '/email_reports/report_react_error/',
        'POST',
        {
            body: JSON.stringify({
                error_message: `${error.message}   ${window.location.href}`,
                error_stack: error.stack,
                error_details: localStorage.getItem('persist:root')
            })
        }
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ErrorBoundary
            fallbackRender={ErrorFallback}
            onError={(error) => reportError(error)}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistStore(store)}>
                <App />
              </PersistGate>
        </Provider>
      </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
