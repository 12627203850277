import React, { useRef, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Separator } from "@/components/ui/separator";
import { useDispatch, useSelector } from "react-redux";
import { signupUser, resetError } from '@/redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { Label } from '@/components/ui/label';
import { isValidEmail, isValidPassword } from '@/utils/tools';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import logo from '@/assets/icons/logo-vertical.png';

const SignupForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const firstNameRef = useRef('');
    const lastNameRef = useRef('');
    const emailRef = useRef('');
    const passwordRef = useRef('');

    const errorMessage = useSelector(state => state.user?.errorMessage);
    const token = useSelector(state => state.user?.token);

    const [errors, setErrors] = useState({});
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        dispatch(resetError());
    }, [dispatch]);

    useEffect(() => {
        token && navigate('/create-business');
    }, [token, navigate]);

    const handleSignup = async (e) => {
        e.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        const newErrors = {};
        if (!firstName || firstName.length < 2) newErrors.firstName = 'Please fill in your first name';
        if (!lastName || lastName.length < 2) newErrors.lastName = 'Please fill in your last name';
        if (!email || !isValidEmail(email)) newErrors.email = 'Please fill in your email address';
        if (!password) newErrors.password = 'Please fill in your password';
        if (password && !isValidPassword(password)) {
            newErrors.password = 'Password must be at least 8 characters long and contain a letter and a number.'
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            return;
        }

        const recaptchaToken = await executeRecaptcha('signup');

        dispatch(signupUser({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            username: email.trim(),
            password: password.trim(),
            recaptchaToken: recaptchaToken,
        }));
    };

    return (
        <div className="flex items-start justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col items-center mt-8 w-[500px]">
                <Link to="/">
                    <img src={logo} alt="Logo" width="350" />
                </Link>
                <Card className="w-full max-w-lg px-7 py-6 bg-white shadow-md rounded-lg">
                    <h2 className="text-2xl font-weight-500 text-center mb-6">Create your account</h2>
                    <form onSubmit={handleSignup}>
                        <div className="grid grid-cols-2 gap-5 col-span-12 mt-4 mb-1">
                            <Label htmlFor="firstName" className="font-weight-400">First name</Label>
                            <Label htmlFor="lastName" className="font-weight-400">Last name</Label>
                        </div>
                        <div className="grid grid-cols-2 gap-5 col-span-12 mb-4">
                            <div>
                                <Input id="firstName" ref={firstNameRef} className="mt-1" />
                                {errors.firstName &&
                                    <p className="text-red-500 text-sm mt-1 w-max">{errors.firstName}</p>}
                            </div>
                            <div>
                                <Input id="lastName" ref={lastNameRef} className="mt-1" />
                                {errors.lastName &&
                                    <p className="text-red-500 text-sm mt-1 w-max">{errors.lastName}</p>}
                            </div>
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="email" className="font-weight-400">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                ref={emailRef}
                                required
                                className="mt-1 block w-full"
                            />
                            {errors.email &&
                                    <p className="text-red-500 text-sm mt-1 w-max">{errors.email}</p>}
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="password" className="font-weight-400">Password</Label>
                            <Input
                                id="password"
                                type="password"
                                ref={passwordRef}
                                required
                                className="mt-1 block w-full"
                            />
                            {errors.password &&
                                    <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                        </div>
                        <Button type="submit" className="w-full py-2 rounded-md text-lg">
                            Sign up
                        </Button>
                        <p className="text-xs text-center px-6 mt-1.5">
                            By signing up to bestShot.ai, you agree to the&nbsp;
                            <Link to="/terms-and-service" className="text-primary font-weight-400 hover:underline">
                                Terms of Service
                            </Link>
                            &nbsp;&amp;&nbsp;
                            <Link to="/privacy-policy" className="text-primary font-weight-400 hover:underline">
                                Privacy Policy.
                            </Link>
                        </p>
                        {errorMessage && <p className="mb-4 mt-2 text-center text-red-500">{errorMessage}</p>}
                        <p className="text-center mt-4">
                            Already have an account?&nbsp;
                            <Link to="/login" className="font-weight-400 text-primary cursor-pointer">
                                Log in
                            </Link>
                        </p>
                    </form>
                    <Separator className="my-3 bg-black bg-opacity-20"/>
                    <p className="text-md text-center">
                        Need help accessing your account?&nbsp;<br/>
                        Get in touch via&nbsp;
                        <a href="mailto:support@bestshot.ai" className="text-primary">
                            support@bestshot.ai
                        </a>
                    </p>
                </Card>
            </div>
        </div>
    );
};

const Signup = () => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <SignupForm />
    </GoogleReCaptchaProvider>
);

export default Signup;
