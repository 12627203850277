import React, { useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { useDispatch, useSelector } from "react-redux";
import { CardContent, CardHeader } from '@/components/ui/card';
import { Spinner } from '@/components/ui/spinner';
import { getEvents } from "@/redux/actions";
import EventsList from "@/components/Events/EventsList";
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import NoEventsPlaceholder from "@/components/Events/NoEventsPlaceholder";
import AddEventModal from "@/components/Events/AddEventModal";
import withHeaderAndFooter from '@/components/HOCs/withHeaderAndFooter';


const Events = () => {
    const dispatch = useDispatch();
    const businessId = useSelector(state => state.user?.business?.id);
    const verified = useSelector(state => state.user?.business?.verified);
    const { isEventsLoading } = useSelector(state => state.events.state);
    const { events } = useSelector(state => state.events);

    useEffect(() => {
        businessId && dispatch(getEvents({ businessId }));
    }, [dispatch, businessId]);

    return (
        <div>
            <main className="grid flex-1 bg-gray-100 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <Card className="flex container max-w-screen-2xl flex-col mt-6">
                    <CardHeader className="flex justify-between text-left mb-10">
                        {!verified &&
                            <Alert className="border-yellow-500 bg-yellow-100/60 mb-4">
                                <AlertTitle className="text-yellow-700">Almost ready!</AlertTitle>
                                <AlertDescription className="text-yellow-700">
                                    Our team is reviewing your business, and you'll be able to set up your events very soon.
                                </AlertDescription>
                            </Alert>
                        }
                        <div className="flex justify-between text-left">
                            <div>
                                <p className="text-3xl font-weight-500 text-neutral-800">Events</p>
                                <p className="text-md text-muted-foreground">Manage your events and view their status.</p>
                            </div>
                            <AddEventModal verified={verified}/>
                        </div>
                    </CardHeader>
                    <CardContent>
                        {isEventsLoading ?
                             <Spinner className="mt-4" size="large">
                                 <span className="py-3 text-gray-800">Loading your events...</span>
                             </Spinner>
                            :
                            <>
                                {events.length > 0 ?
                                    <EventsList events={events} />
                               :
                                    <NoEventsPlaceholder verified={verified}/>
                                }
                            </>
                        }
                    </CardContent>
                </Card>
            </main>
        </div>
    );
};

export default withHeaderAndFooter(Events);
