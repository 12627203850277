import React from 'react';
import Select from 'react-select';

const CountryDropdownSelector = ({ countries, selectedCountry, onChange }) => {
    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'hsl(0, 0%, 70%)' : 'hsl(0, 0%, 90%)',
            '&:hover': {
                borderColor: 'hsl(0, 0%, 70%)',
            },
            boxShadow: state.isFocused ? '0 0 0 1px hsl(0, 0%, 70%)' : 'none',
        }),
    };

    const options = countries.map((country) => ({
        value: country.code,
        label: country.name, // Use plain text for filtering
        imgSrc: `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_DOMAIN}/${country.flag}`
    }));

    const formatOptionLabel = ({ label, imgSrc }) => (
        <div className="flex items-center">
            <img
                src={imgSrc}
                alt={label}
                className="w-4 h-4 mr-2"
            />
            {label}
        </div>
    );

    return (
        <Select
            styles={customStyles}
            value={options.find(option => option.value === selectedCountry)}
            onChange={(selectedOption) => onChange({ target: { value: selectedOption.value } })}
            options={options}
            placeholder="Select a country"
            formatOptionLabel={formatOptionLabel}
        />
    );
};

export default CountryDropdownSelector;
