import { combineReducers } from 'redux';
import user from './user';
import photos from './photos';
import events from './events';
import businesses from './businesses';
import lookups from './lookups';

export default combineReducers({
    user,
    photos,
    events,
    businesses,
    lookups
});
