import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { Table, TableRow, TableHead, TableCell, TableHeader, TableBody } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';



const EventsList = ({ events }) => {
    return (
        <Table className="table-auto w-full">
            <TableHeader>
                <TableRow>
                    <TableHead className="hidden w-[100px] sm:table-cell">
                        <span className="sr-only">Image</span>
                    </TableHead>
                    <TableHead
                        className="text-left max-w-[100px] sm:max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">Event</TableHead>
                    <TableHead className="text-center">Status</TableHead>
                    <TableHead className="text-center">Photos</TableHead>
                    <TableHead className="text-center">Portraits</TableHead>
                    <TableHead className="text-center hidden md:table-cell">
                        Event Date
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    events.map((event, index) => (
                            <TableRow key={index}>
                                <TableCell className="hidden sm:table-cell">
                                    <img
                                        alt={event.title}
                                        className="aspect-square rounded-md object-cover"
                                        height="40"
                                        width="40"
                                        src={event.main_image}

                                    />
                                </TableCell>
                                <TableCell
                                    className="font-medium max-w-[100px] md:max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                                    {event.title}
                                </TableCell>
                                <TableCell className="flex justify-center items-center">
                                    <Badge
                                        variant={event.status.toLowerCase()}>{event.status}</Badge>
                                </TableCell>
                                <TableCell className="text-center hidden md:table-cell">
                                    {event.photos_count}
                                </TableCell>
                                 <TableCell className="text-center hidden md:table-cell">
                                    {event.classification ? event.portraits_count : '-'}
                                </TableCell>
                                <TableCell className="text-center hidden md:table-cell">
                                    {moment(event.starting_date).format("DD MMM YYYY")}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/events/${event.id}`}>
                                    <div className='flex justify-center items-center cursor-pointer font-weight-400 text-lg text-primary hover:underline'>
                                        <span className='mr-1'>Edit</span>
                                        {/* <Pencil className='w-5 h-5 ml-1'/> */}
                                        &rarr;
                                    </div>
                                        
                                    </Link> 
                                </TableCell>
                            </TableRow>
                        )
                    )
                }
            </TableBody>
        </Table>
    );
};

EventsList.propTypes = {
    events: PropTypes.array,
};

export default EventsList;