import { call, put } from 'redux-saga/effects';
import * as a from '@/redux/actions/lookups';
import { request } from '@/utils/request';

export function *getCountriesSaga () {
    try {
        const response = yield call(
            request,
            `/lookup/countries/`,
            'GET'
        );

        yield put(a.getCountriesSuccess(({
            payload: response
        })));

    } catch (error) {
        console.log('error', error);
        if (error instanceof Response) {
            const errorData = yield error.json().catch(jsonError => console.error('Failed to parse error response as JSON:', jsonError) || null);
            errorData && console.log(errorData);
        }
        yield put(a.getCountriesFailure());
    }
}
