import { call, put, select } from 'redux-saga/effects';
import * as a from '@/redux/actions';
import { getSignedUrlAndUploadFile } from '@/utils/getSignedUrlAndUploadFile';
import { toast } from 'react-toastify';

export function *uploadPhotoSaga ({ file, eventId, isPortrait = false }) {
    try {
        yield call(getSignedUrlAndUploadFile, file, eventId, isPortrait, 'PUT', true);
        yield put(a.uploadPhotoSuccess(({ file })));
    } catch (error) {
        console.log(error, file);

        if (error instanceof Response) {
            const errorJson = yield error.json();

            if (error.status === 429) {
                console.log('Rate limit exceeded. Stopping further uploads.');
                let errorMessage = errorJson?.error || 'There was an error uploading your photo.';
                console.log(errorMessage)
                toast.error(errorMessage);
                return 429;
            }
        }

        yield put(a.uploadPhotoFailure({ file }));
    }
}

export function *uploadPhotosSaga({ files, eventId, isPortraits }) {
    const businessId = yield select(state => state.user.business?.id);
    let failedIndex = null;

    for (let i = 0; i < files.length; i++) {
        const result = yield call(uploadPhotoSaga, { file: files[i], eventId, isPortrait: isPortraits });

        if (result === 429) {
            failedIndex = i;
            break;
        }
    }

    if (failedIndex !== null) {
        for (let i = failedIndex; i < files.length; i++) {
            yield put(a.uploadPhotoFailure({ file: files[i] }));
        }
    }

    const objectType = isPortraits ? 'portrait' : 'photo';
    yield put(a.uploadPhotosCompleted({ businessId, eventId, objectType }));
    yield put(a.getEvent({ businessId, eventId }));
    yield put(a.getBusiness({ businessId }));
}