import React, { useEffect, useState } from 'react';
import { Card, CardContent, } from '@/components/ui/card';
import {Minus, CalendarDays, ImageUp, SquareUserRound, ScanFace, Edit, LockKeyhole, Globe} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Switch } from '@/components/ui/switch';
import moment from 'moment';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import DateRangePicker from "../ui/DateRangePicker";


const EventDetails = ({ event, isEditMode, editedEvent, setEditedEvent, errors }) => {
    const {
        title,
        description,
        slug,
        starting_date,
        ending_date,
        main_image,
        status,
        photos_count,
        portraits_count,
        classification,
        public: isPublic
    } = event;

    const [localDate, setLocalDate] = useState({
        from: new Date(editedEvent?.starting_date) || new Date(),
        to: new Date(editedEvent?.ending_date) || null
    });

    useEffect(() => {
        setLocalDate({
            from: editedEvent?.startingDate ? new Date(editedEvent?.startingDate) : null,
            to: editedEvent?.endingDate ? new Date(editedEvent?.endingDate) : editedEvent?.startingDate
        });
    }, [editedEvent]);

    const handleDateChange = (date) => {
        setLocalDate(date);
        if (date) {
            setEditedEvent((prevState) => ({
                ...prevState,
                startingDate: moment(date?.from).format("YYYY-MM-DD"),
                endingDate: date?.to ? moment(date?.to).format("YYYY-MM-DD") : moment(date?.from).format("YYYY-MM-DD")
            }));
        } else {
            setEditedEvent((prevState) => ({
                ...prevState,
                startingDate: null,
                endingDate: null
            }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setEditedEvent((prevState) => ({
                ...prevState,
                mainImage: URL.createObjectURL(file),
                mainImageFile: file
            }));
        }
    };

    return (
        <CardContent>
            <div className="flex flex-wrap lg:flex-nowrap gap-4">
                <div className="order-2 lg:order-1 w-full lg:w-3/5 p-4">
                    <h1 className="text-3xl font-weight-500 lg:text-4xl text-neutral-800">
                        {!isEditMode ?
                            title
                            :
                            <>
                                <Input
                                    id="title"
                                    value={editedEvent?.title}
                                    onChange={(e) => setEditedEvent(prevState => ({ ...prevState, title: e.target.value }))}
                                    className="text-3xl font-weight-500 lg:text-4xl text-neutral-800 py-6"
                                />
                                {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
                            </>
                        }
                    </h1>
                    {!isEditMode ?
                        <>
                            {description &&
                                <p className="mt-2 text-md text-muted-foreground">
                                    {description}
                                </p>
                            }
                        </>
                        :
                        <Textarea
                            id="description"
                            placeholder="Enter your event's description."
                            value={editedEvent?.description}
                            onChange={(e) => setEditedEvent(prevState => ({...prevState, description: e.target.value}))}
                            className="my-4 text-md text-muted-foreground"
                        />
                    }
                    {!isEditMode ?
                        <>
                            {starting_date &&
                                <div className="mt-4 flex">
                                    <CalendarDays className="w-5 mr-2 text-lg text-primary"/>
                                    <p>
                                        <span>{moment(starting_date).format("Do MMMM YYYY")}</span>
                                        {ending_date && ending_date !== starting_date &&
                                            <span>&nbsp;-&nbsp;{moment(ending_date).format("Do MMMM YYYY")}</span>
                                        }
                                    </p>
                                </div>
                            }
                        </>
                        :
                        <>
                            <DateRangePicker date={localDate} handleDateChange={handleDateChange}/>
                            {errors.date && <p className="text-red-500 text-sm mt-1 col-span-3">{errors.date}</p>}
                        </>
                    }
                    {!isEditMode ?
                        <>
                            {isPublic ?
                                <div className="mt-4 flex">
                                    <Globe className="w-5 mr-2 text-lg text-green-500"/>
                                    <p>
                                        <b>Public</b> event gallery:&nbsp;
                                        <a
                                            className="text-primary underline"
                                            href={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_BASE_DOMAIN}/photos/${event.slug}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View&nbsp;&rarr;
                                        </a>
                                    </p>
                                </div>
                                :
                                <div className="mt-4 flex">
                                    <LockKeyhole className="w-5 mr-2 text-lg text-red-500"/>
                                    <p><b>Private</b> event gallery</p>:&nbsp;
                                    <a
                                        className="text-primary underline"
                                        href={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_BASE_DOMAIN}/photos/${event.slug}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        View&nbsp;&rarr;
                                    </a>
                                </div>
                            }
                        </>
                        :
                        <div className="p-4 mt-4 border rounded-sm border-grey-200 grid grid-cols-12 flex justify-center items-bottom">
                            <div className="col-span-10">
                                <h4 className="text-lg font-weight-400">Public event gallery</h4>
                                <p className="text-sm">Publish an event gallery that’s accessible to everyone online.
                                    Viewers can explore event details, photos, and grouped results, and you'll be able
                                    to share a link with anyone.</p>
                            </div>

                            <div className="col-span-2 flex items-center justify-center">
                                <Switch
                                    checked={editedEvent.isPublic}
                                    onCheckedChange={(checked) => setEditedEvent(prevState => ({...prevState, isPublic: checked}))}
                                />
                            </div>


                        </div>
                    }
                    <div className="mt-12 grid grid-cols-3 items-center gap-2">
                        <Card className="p-5">
                            <div className="flex justify-center items-center">
                                <ImageUp className="flex-shrink-0 w-12 h-12 text-primary"/>
                            </div>
                            <div className="mt-5 flex justify-center items-center">
                                <div className="text-center">
                                    <p className="mt-1 text-lg text-muted-foreground font-weight-400">
                                        Photos
                                    </p>
                                    <p className="mt-1 text-3xl font-weight-400">
                                        {photos_count}
                                    </p>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-5">
                            <div className="flex justify-center items-center rounded-lg">
                                <SquareUserRound className="flex-shrink-0 w-12 h-12 text-primary"/>
                            </div>
                            <div className="mt-5 flex justify-center items-center ">
                                <div className="text-center">
                                    <p className="mt-1 text-lg text-muted-foreground font-weight-400">
                                        {classification ?
                                            'Portraits'
                                            :
                                            'Portrait grouping'
                                        }
                                    </p>
                                    <p className="flex justify-center mt-1 text-2xl font-weight-400">
                                        {classification ?
                                            `${portraits_count}`
                                            :
                                            <Minus className="pt-1 pb-1 text-neutral-800 w-9 h-9"/>
                                        }
                                    </p>
                                </div>
                            </div>
                        </Card>
                        <Card className="p-5">
                            <div className="flex justify-center items-center rounded-lg">
                                <ScanFace className="flex-shrink-0 w-12 h-12 text-primary"/>
                            </div>
                            <div className="mt-5 flex justify-center items-center ">
                                <div className="text-center">
                                    <p className="mt-1 text-lg text-muted-foreground font-weight-400">
                                         Status
                                    </p>
                                    <Badge variant={status.toLowerCase()} className="mt-2 h-7.5">
                                        {status}
                                    </Badge>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="order-1 lg:order-2 w-full lg:w-2/5 p-4 lg:max-h-screen">
                    <div className="relative w-full h-64 lg:h-full ">
                        <img src={(isEditMode && editedEvent?.mainImage) || main_image} className="absolute inset-0 w-full h-full object-cover rounded-sm" alt={slug}/>
                        {isEditMode && (
                            <label htmlFor="main_image_upload"
                                   className="absolute top-4 right-4 bg-white p-2 rounded-full cursor-pointer flex items-center justify-center">
                                <Edit className="w-5 h-5 text-primary"/>
                                <input
                                    id="main_image_upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="hidden"
                                />
                            </label>
                        )}
                    </div>
                </div>
            </div>
        </CardContent>
    );
};

export default EventDetails;
