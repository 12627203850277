import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleUploadDownloadProgress } from '@/redux/actions';
import { useBeforeUnload } from '@/utils/hooks';
import { ChevronUp, ChevronDown, Loader2 } from 'lucide-react';
import { Spinner } from '@/components/ui/spinner';


const UploadProgress = () => {
    const dispatch = useDispatch();

    const { eventTitle, totalFiles, pendingFiles, successful, failed } = useSelector((state) => state.photos.uploads);
    const { isUploading, isUploadDownloadProgressMinimized } = useSelector((state) => state.photos.state);
    const { isZipping } = useSelector((state) => state.events.state );
    const progress = isUploading && totalFiles > 0 ? ((totalFiles - pendingFiles) / totalFiles * 100).toFixed(0) : 100;

    const isInProgress = isUploading || isZipping;
    useBeforeUnload(isInProgress, `Wait! Your ${isZipping ? 'download' : 'upload'} is in progress. If you leave this page now, the upload will be interrupted.`);

    const toggleMinimize = () => {
        dispatch(toggleUploadDownloadProgress());
    };

    const filesString = (filesCount) => {
        return filesCount !== 1 ? 'files' : 'file';
    }

    if (!successful.length && !failed.length && !isZipping) {
        return <></>;
    }

    return (
        <div className="fixed bottom-0 right-0 m-4 p-2 bg-white shadow-xl rounded p-4 border-2 border-gray-200/35 z-50">
            <button
                onClick={toggleMinimize}
                className="absolute top-0 right-0 mr-2 text-gray-500 hover:text-gray-700 text-xl"
            >
                {isUploadDownloadProgressMinimized ?
                    <ChevronUp/>
                    :
                    <ChevronDown/>
                }
            </button>
            <div className="relative">
                <div className={`relative w-80 ${isUploadDownloadProgressMinimized ? 'h-7 ' : 'h-26'}`}>
                    {!isUploadDownloadProgressMinimized &&
                        <h5 className="text-muted-foreground font-weight-400 my-2 text-sm overflow-hidden text-ellipsis whitespace-nowrap">{eventTitle}</h5>
                    }
                    <p className="font-weight-400">
                        {isZipping ?
                            <>
                                {!isUploadDownloadProgressMinimized ?
                                    <Spinner className="mt-4" size="medium">
                                        <span className="mt-3">Preparing your files...</span>
                                    </Spinner>
                                    :
                                    <div className="flex mt-2">
                                        {isUploadDownloadProgressMinimized &&
                                            <Loader2 className="mr-2 text-primary h-6 w-6 animate-spin"/>}
                                        <span>Preparing your files...</span>
                                    </div>
                                }
                            </>
                            : isUploading ?
                                <div className="flex align-center items-center">
                                    {isUploadDownloadProgressMinimized &&
                                        <Loader2 className="mr-2 text-primary h-6 w-6 animate-spin"/>}
                                    <span>Uploading {totalFiles} {filesString(totalFiles)}...</span>
                                </div>
                                :
                                <>
                                    {successful.length > 0 ?
                                        `${failed.length === 0 ? '✅' : '⚠️'} Successfully uploaded ${successful.length} ${filesString(successful.length)}.`
                                        :
                                        `❌ Failed to upload ${failed.length} ${filesString(failed.length)}.`
                                    }
                                </>

                        }
                    </p>
                    {!isUploadDownloadProgressMinimized && !isZipping &&
                        <>
                            <div className="flex justify-start items-center h-6 bg-gray-400/90 rounded my-3">
                                <div className="absolute h-6 bg-blue-600 rounded text-center"
                                     style={{width: `${progress}%`}}/>
                                <p className="absolute top-7 inset-0 flex justify-center items-center text-white font-weight-400">{progress}%</p>
                            </div>
                            <p className="text-center">
                                <span className="text-green-700">{successful.length} successful</span>
                                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                <span className="text-red-700">{failed.length} failed</span></p>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default UploadProgress;