import React from 'react';
import AddEventModal from "./AddEventModal";

const NoEventsPlaceholder = ({ verified }) => {
    return (
        <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm p-5">
            <div className="flex flex-col items-center gap-1 text-center p-5">
                <h3 className="text-2xl font-weight-400">
                    You have no events
                </h3>
                <p className="text-md text-muted-foreground mt-0 mb-5">
                    Unlock the power of AI by adding your first event.
                </p>
                <AddEventModal verified={verified} />
            </div>
        </div>
    );
};

export default NoEventsPlaceholder;